import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { DataService } from './service/data.service';
import { ExchangeRateService } from './service/exchangeRate.service';
import { ProgressSpinnerComponent } from './spinner/progress-spinner.component';
import { ToastShowComponent } from './spinner/toastShow.component';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { UserService } from './service/user.service';
import { AuthenticationService } from './service/authentication.service';
import { Meta } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { UrlService } from './service/url.service';
import { DOCUMENT, Location, isPlatformBrowser } from '@angular/common';

setCulture('fr');

L10n.load({
  fr: {
    grid: {
      EmptyRecord: 'Aucun enregistrement affiché',
      Search: 'Rechercher'
    },
    pager: {
      currentPageInfo: '{0} de {1} pages',
      pagerDropDown: 'objets par page',
      pagerAllDropDown: 'objets',
      totalItemsInfo: '	({0} objets)',
      totalItemInfo: '	({0} objet)'
    }
  },
  en: {
    grid: {
      EmptyRecord: 'No records displayed',
      Search: 'Search'
    },
    pager: {
      currentPageInfo: '{0} of {1} pages',
      pagerDropDown: 'items by page',
      pagerAllDropDown: 'items',
      totalItemsInfo: '	({0} items)',
      totalItemInfo: '	({0} item)'
    }
  }
});

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [ProgressSpinnerComponent, ToastShowComponent, RouterModule]
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = [];
  private cssLink: HTMLLinkElement | null = null;
  public isBrowser = isPlatformBrowser(this.platformId);
  public lang = 'fr';
  constructor(
    private translate: TranslateService,
    private cookieService: CookieService,
    private ssrCookieService: SsrCookieService,
    private router: Router,
    private dataService: DataService,
    private exchangeRateService: ExchangeRateService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private metaTagService: Meta,
    private urlService: UrlService,
    private location: Location,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const existingScripts = this.document.querySelectorAll(
          'script[type="application/ld+json"]'
        );

        existingScripts.forEach((script) => {
          this.renderer.removeChild(this.document.head, script);
        });

        let image = '';
        if (this.translate.currentLang === 'fr')
          image = 'https://lokatix.net/assets/img/lokatix_logo_green_fr.svg';
        else image = 'https://lokatix.net/assets/img/lokatix_logo_green_en.svg';
        const jsonLdObject = {
          '@context': 'https://schema.org',
          '@type': 'Organization',
          name: 'Lokatix',
          url: 'https://lokatix.net',
          description: this.translate.instant(
            'Rental of apartments, houses and rooms with Lokatix.net!'
          ),
          email: 'info@lokatix.net',
          telephone: '873-886-8657',
          image: image,
          sameAs: [
            'https://www.facebook.com/lokatix.net',
            'https://pin.it/h0HwF32Of',
            'https://www.instagram.com/lokatixnet',
            'https://twitter.com/innologiciel'
          ],
          address: {
            '@type': 'PostalAddress',
            streetAddress: '53 17e avenue',
            addressLocality: 'Drummondville',
            addressCountry: 'CA',
            addressRegion: 'Centre du Québec',
            postalCode: 'J2B 3J5'
          },
          logo: image
        };

        const script = this.renderer.createElement('script');

        this.renderer.setAttribute(script, 'type', 'application/ld+json');
        script.textContent = JSON.stringify(jsonLdObject);
        this.renderer.appendChild(this.document.head, script);
      }
    });
  }

  ngOnInit() {
    let lang = '';
    let aspNetCulture = '';
    if (this.isBrowser) {
      aspNetCulture = this.cookieService.get('.AspNetCore.Culture');
    } else {
      aspNetCulture = this.ssrCookieService.get('.AspNetCore.Culture');
    }

    const url = this.location.path();
    this.translate.addLangs(['fr', 'en']);

    if (aspNetCulture && url === '/') {
      lang = aspNetCulture.substring(2, 4);
      if (lang !== 'en' && lang !== 'fr') lang = 'fr';

      if (url.startsWith('/en')) lang = 'en';
    } else {
      this.translate.setDefaultLang('fr');

      if (url.startsWith('/en')) lang = 'en';
      else lang = 'fr';
    }
    this.translate.use(lang);
    if (this.location.path() === '') {
    } else {
      if (this.isBrowser) {
        this.cookieService.set(
          '.AspNetCore.Culture',
          'c=' + lang + '|uic=' + lang,
          {
            expires: 7,
            path: '/',
            domain: null,
            secure: true,
            sameSite: 'Strict'
          }
        );
      }
    }

    if (environment.hideGoogleIndex)
      this.metaTagService.addTags([{ name: 'robots', content: 'noindex' }]);

    if (this.authenticationService.getJwt() !== '') {
      this.userService.getCurrency().subscribe({
        next: (val) => {
          if (+val === 2) {
            this.exchangeRateService.getExchangeRate().subscribe({
              next: (val) => {
                this.dataService.SetExchangeRate(val);
              }
            });
            this.dataService.SetCurrency(val);
          }
        }
      });
    } else {
      this.dataService.SetCurrency(1);
    }

    this.urlService.setLangLoad(lang);

    if (this.isBrowser === false) {
      const html = this.document.documentElement;
      html.setAttribute('lang', lang);
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((s) => s.unsubscribe());
    if (this.cssLink) {
      this.renderer.removeChild(this.document.head, this.cssLink);
      this.cssLink = null;
    }
  }
}
