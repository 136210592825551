<!-- <ejs-toast #toasttype [position]='position' [showProgressBar]="showProgressBar"></ejs-toast> -->
<ejs-toast #toasttype [position]='position' [showProgressBar]="showProgressBar"
        newestOnTop=true (beforeOpen)="onBeforeOpen($event)">
  <ng-template #template>
    <div [ngClass]="dataToast.cssClass ? 'customToast ' + dataToast.cssClass  : 'customToast' "  >
      <div class="wrapIcon" >
        <img src="/assets/img/ic_toast_success.webp" class="icon iconToastSuccess" alt="{{'lokatix rental apartment' | translate}} {{'ic_toast_success' | translate}}"
             title="{{'lokatix rental apartment' | translate}} {{'ic_toast_success' | translate}}">
        <img src="/assets/img/ic_toast_error.webp" class="icon iconToastError" alt="{{'lokatix rental apartment' | translate}} {{'ic_toast_error' | translate}}"
             title="{{'lokatix rental apartment' | translate}} {{'ic_toast_error' | translate}}">
        <img src="/assets/img/ic_toast_warning.webp" class="icon iconToastInfo" alt="{{'lokatix rental apartment' | translate}} {{'ic_toast_warning' | translate}}"
             title="{{'lokatix rental apartment' | translate}} {{'ic_toast_warning' | translate}}">
      </div>
      <div class="wrapInfo">
        @if (title) {
          <p class="titleToast">
            {{ title | translate}}
          </p>
        }
        @if (content) {
          <p class="contentToast">
            {{ content | translate}}
          </p>
        }
      </div>
      <button class="btnCloseToast" (click)="closeToast()" [attr.aria-label]="('lokatix rental apartment' | translate) + ' ' + ('Close' | translate)">
        <img class="" src="/assets/img/close.svg" alt="{{'lokatix rental apartment' | translate}} {{'close' | translate}}"
             title="{{'lokatix rental apartment' | translate}} {{'close' | translate}}">
      </button>
    </div>
  </ng-template>
</ejs-toast>
